@tailwind base;
@tailwind components;
@tailwind utilities;

hr {
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;
  height: 1px;
  width: 3rem;
  opacity: 0.1;
}

.view-text {
  color: #ffffff;
  font-size: 11px;
  text-shadow: 0px 0px 5px #000000;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-gray-800\/50 {
  background-color: rgb(30 41 59/0.5);
}

.ring-gray-500\/20 {
  --tw-ring-color: rgb(100 116 139/0.2);
}

.ring-inset {
  --tw-ring-inset: inset;
}
.ring-1,
.ring-2 {
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring-1 {
  /* --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color); */
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}
